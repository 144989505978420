import {getPartsByDelimiter, YesNo, PROGRAM_IDS} from "helper/Methods"
import {toJS} from "mobx"
import {Model} from "./Model"

export class ModelListener {
    private cache = {}
    private MANHATTAN_CAMPUS = "Manhattan"

    constructor(private model: Model) {
        this.onModel = this.onModel.bind(this)

        model.dispatcher2.addListener(this.onModel)
        this.onModel()
    }

    private onModel() {
        const json_data = this.model.document.json_data

        const enable = this.model.enableForm.bind(this.model)
        const disable = this.model.disableForm.bind(this.model)
        const enableTab = this.model.enableTabs.bind(this.model)
        const disableTab = this.model.disableTabs.bind(this.model)
        // const includeTab = this.model.includeTabs.bind(this.model)
        // const removeTab = this.model.removeTabs.bind(this.model)

        // disableTab("disabilites")
        // disableTab("ready_to_enroll")
        disableTab("forms")
        disableTab("upload_docs")
        disableTab("submission")

        // removeTab("disabilites")
        // removeTab("ready_to_enroll")

        enable("application_career_information")
        disable("enrollment_application")

        disable("academic_adjustment_form")
        disable("application_upload_disability_doc")
        disable("application_ready_to_enroll")
        disable("opt_outs")
        disable("enrollment_agreement")
        disable("arbitration_agreement")
        disable("school_performance_fact")
        disable("distance_education_disclosure")
        disable("freelance_selfemployed_disclosure")
        disable("checklist_page")
        disable("criminal_disclosure")
        disable("ferpa_release_form")
        disable("overtime_disclosure")
        disable("application_upload_docs")
        disable("submission")
        if (json_data.extra && this.model.campusesInfo && this.model.campusesLinksInfo) {
            // console.log("@@@@@@@ data:", {
            //     extra: toJS(json_data.extra),
            //     campuses: toJS(this.model.campusesInfo),
            //     campusesLinks: toJS(this.model.campusesLinksInfo)
            // })
            const {hasCareerInformation, campus} = json_data
            if (hasCareerInformation) {
                const [campusSelectedId, ,] = getPartsByDelimiter(campus)
                const [, , , {id: manhattanCampusId}] = this.model.campusesInfo
                enableTab("forms")
                enable("enrollment_application")
                if (
                    json_data.extra &&
                    json_data.isApplicationSubmitted &&
                    json_data.disabilitySelected &&
                    json_data.disabilites === YesNo.yes
                ) {
                    enable("academic_adjustment_form")
                    enable("application_upload_disability_doc")
                    if (
                        json_data.extra &&
                        json_data.extra.campus &&
                        json_data.extra.campus.id === manhattanCampusId &&
                        json_data.isApplicationSubmitted &&
                        json_data.disabilitySelected &&
                        json_data.disabilitySubmitted &&
                        json_data.isValidCampusCode &&
                        json_data.hasCampusCode
                    ) {
                        disable("school_performance_fact")
                    } else if (
                        json_data.extra &&
                        json_data.extra.campus &&
                        json_data.extra.campus.id !== manhattanCampusId &&
                        json_data.isApplicationSubmitted &&
                        json_data.disabilitySelected &&
                        json_data.disabilitySubmitted &&
                        json_data.isValidCampusCode &&
                        json_data.hasCampusCode
                    ) {
                        enable("school_performance_fact")
                    }
                }

                if (
                    json_data.extra &&
                    json_data.isApplicationSubmitted &&
                    json_data.disabilitySelected &&
                    json_data.disabilites === YesNo.no
                ) {
                    disable("academic_adjustment_form")
                    disable("application_upload_disability_doc")
                    disable("application_ready_to_enroll")

                    if (
                        json_data.extra &&
                        json_data.extra.campus &&
                        json_data.extra.campus.id === manhattanCampusId &&
                        json_data.isApplicationSubmitted &&
                        json_data.disabilitySelected
                    ) {
                        disable("school_performance_fact")
                    } else if (
                        json_data.extra &&
                        json_data.extra.campus &&
                        json_data.extra.campus.id !== manhattanCampusId &&
                        json_data.isApplicationSubmitted &&
                        json_data.disabilitySelected
                    ) {
                        enable("school_performance_fact")
                    }

                    enableTab("upload_docs")
                    enableTab("submission")

                    enable("enrollment_application")
                    enable("opt_outs")
                    enable("enrollment_agreement")
                    if (
                        json_data.extra &&
                        json_data.extra.program &&
                        json_data.extra.program.id === PROGRAM_IDS.makeup_artistry.id
                    ) {
                        enable("freelance_selfemployed_disclosure")
                    } else {
                        disable("freelance_selfemployed_disclosure")
                    }
                    enable("arbitration_agreement")
                    enable("distance_education_disclosure")
                    enable("checklist_page")
                    enable("criminal_disclosure")
                    enable("ferpa_release_form")
                    enable("overtime_disclosure")
                    enable("application_upload_docs")
                    enable("submission")
                }

                if (
                    json_data.extra &&
                    json_data.isApplicationSubmitted &&
                    json_data.disabilitySelected &&
                    !json_data.disabilitySubmitted
                ) {
                    disable("application_ready_to_enroll")
                }

                if (
                    json_data.extra &&
                    json_data.isApplicationSubmitted &&
                    json_data.disabilitySelected &&
                    json_data.disabilitySubmitted &&
                    json_data.disabilites === YesNo.yes
                ) {
                    enable("application_ready_to_enroll")
                }

                if (
                    json_data.extra &&
                    json_data.isApplicationSubmitted &&
                    json_data.disabilitySelected &&
                    json_data.disabilitySubmitted &&
                    json_data.disabilites === YesNo.yes &&
                    json_data.isValidCampusCode &&
                    json_data.hasCampusCode
                ) {
                    disable("application_ready_to_enroll")

                    enableTab("upload_docs")
                    enableTab("submission")

                    enable("enrollment_application")
                    enable("opt_outs")
                    enable("enrollment_agreement")
                    if (
                        json_data.extra &&
                        json_data.extra.program &&
                        json_data.extra.program.id === PROGRAM_IDS.makeup_artistry.id
                    ) {
                        enable("freelance_selfemployed_disclosure")
                    } else {
                        disable("freelance_selfemployed_disclosure")
                    }
                    enable("arbitration_agreement")
                    enable("distance_education_disclosure")
                    enable("checklist_page")
                    enable("criminal_disclosure")
                    enable("ferpa_release_form")
                    enable("overtime_disclosure")
                    enable("application_upload_docs")
                    enable("submission")
                }
            }
        }
        // todo: do not delete this, force refresh needed in the listener.
        disable("init_one_time")
    }
}
